// @codekit-prepend "../bower_components/slick-carousel/slick/slick.min.js";
// @codekit-prepend "../bower_components/telex/dist/telex.js";
// @codekit-prepend "../bower_components/featherlight/release/featherlight.min.js";
// @codekit-prepend "../bower_components/ScrollToFixed/jquery-scrolltofixed-min.js";

// @codekit-prepend "../bower_components/waypoints/lib/jquery.waypoints.min.js";
// @codekit-prepend "../bower_components/waypoints/lib/shortcuts/inview.min.js";
// @codekit-prepend "../bower_components/waypoints/lib/shortcuts/sticky.min.js";


/*

var html = document.documentElement;
var body = document.body;

var scroller = {
  target: document.querySelector("#scroll-container"),
  ease: 0.05, // <= scroll speed
  endY: 0,
  y: 0,
  resizeRequest: 1,
  scrollRequest: 0,
};

var requestId = null;

TweenLite.set(scroller.target, {
  rotation: 0.01,
  force3D: true
});

window.addEventListener("load", onLoad);

function onLoad() {
  updateScroller();
  window.focus();
  window.addEventListener("resize", onResize);
  document.addEventListener("scroll", onScroll);
}

function updateScroller() {

  var resized = scroller.resizeRequest > 0;

  if (resized) {
    var height = scroller.target.clientHeight;
    body.style.height = height + "px";
    scroller.resizeRequest = 0;
  }

  var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

  scroller.endY = scrollY;
  scroller.y += (scrollY - scroller.y) * scroller.ease;

  if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
    scroller.y = scrollY;
    scroller.scrollRequest = 0;
  }

  TweenLite.set(scroller.target, {
    y: -scroller.y
  });

  requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
}

function onScroll() {
  scroller.scrollRequest++;
  if (!requestId) {
    requestId = requestAnimationFrame(updateScroller);
  }
}

function onResize() {
  scroller.resizeRequest++;
  if (!requestId) {
    requestId = requestAnimationFrame(updateScroller);
  }
}

*/

/* Globale Variable zur Speichern der aktuellen Browserbreite */
var windowWidth = 0;
var windowHeight = 0;
window.addEventListener("resize", getWindowSize);

function getWindowSize() {
  windowWidth = document.documentElement.clientWidth;
  windowHeight = document.documentElement.clientHeight;

  return [windowWidth, windowHeight];
}

function convertSvg() {
	jQuery('img.svg').each(function(){
		var $img = jQuery(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');
		jQuery.get(imgURL, function(data) {
			// Get the SVG tag, ignore the rest
			var $svg = jQuery(data).find('svg');
			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}
			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');
			// Replace image with new SVG
			$img.replaceWith($svg);
		}, 'xml');
	});
}

$(document).ready(function() {
  convertSvg();
  getWindowSize();

  if(windowWidth > 768) {
    jQuery('.headline-content').each(function() {
      var thisElem = $(this);
      var inview = new Waypoint.Inview({
        offset: {
          top: 300, // 300px
        },
        element: thisElem[0],
        entered: function(direction) {
          setTimeout(function() {
            thisElem.addClass("animated fadeInLeft");
          }, 200);
        },
        exited: function() {
          thisElem.removeClass("animated fadeInLeft");
        }
      })
    });
  }

  /* Stellenangebote filtern */
  var switchStellenTimeout = null;
  jQuery(document).on("click", ".stellenangebote-container .type-selector", function() {
    if(!jQuery(this).hasClass("active")) {
      var target = jQuery(this).data("target");
      jQuery(".stellenangebote-container .type-selector").removeClass("active");
      jQuery(this).addClass("active");

      if(target == "all") {
        jQuery(".stellenangebote-container .stelle").fadeIn("fast");
      } else {
        if(switchStellenTimeout != null) {
          clearTimeout(switchStellenTimeout);
        }
        jQuery(".stellenangebote-container .stelle").fadeOut("fast", function() {
          switchStellenTimeout = setTimeout(function() {
            jQuery(".stellenangebote-container .stelle."+target).fadeIn("fast");
          }, 300);
        });
      }
    }
  });

  /* Höhe des Textcontainers bei Stories berechnen */
  if(jQuery(".section--stories .intro .image-container").length) {
    var containerHeight = jQuery(".section--stories .intro .image-container").height();
    jQuery(".section--stories .intro .text-container").css({"height": containerHeight});
  }

  /* Mobile Menu */
  jQuery(document).on("click", ".nav-icon", function() {
		jQuery(".nav-icon, .nav").toggleClass('open');
	});

  jQuery(document).on("click", "span.sectiontop", function() {
    $('body,html').animate({scrollTop: 0}, 800);
  })

  $(".anchor, nav ul li a.anchor").click(function(e) {
    e.preventDefault();
    var element = $("section"+$(this).attr("href"));
    $('body,html').animate({scrollTop: element.offset().top}, 800);
  });

  jQuery(document).on("click", ".inner-anchor", function(e) {
    e.preventDefault();
    var element = jQuery(jQuery(this).attr("href"));
    jQuery('body,html').animate({scrollTop: element.offset().top - 40}, 800);
  });

  $('.section--news-slider').slick({
    dots: true,
    arrows: true,
    slidesToShow: 2,
    responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            arrows: false
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        }
      ]
  });

  $('.animatein').each(function() {
    var thisElem = $(this);
    var inview = new Waypoint.Inview({
      element: thisElem[0],
      enter: function(direction) {
        var animation = thisElem.data("animation");
        if(typeof(animation) != "undefined") {
          thisElem.addClass("animated "+animation);
        } else {
          thisElem.addClass("animated fadeInUp");
        }
      }
    })
  });

  $('.animateinleft').each(function() {
    var thisElem = $(this);

    var inview = new Waypoint.Inview({
      element: thisElem[0],
      enter: function(direction) {
          thisElem.addClass("animated slideInLeft");
      }
    })
  });

  if(jQuery(".logo--fixed-loup").length) {
    var sticky1 = new Waypoint.Sticky({
      element: $('.logo--fixed-loup')[0]
    });
  }

  if(jQuery(".logo--fixed-adventure7").length) {
    var sticky2 = new Waypoint.Sticky({
      element: $('.logo--fixed-adventure7')[0]
    });
  }

  if(jQuery(".logo--fixed-featfosbury").length) {
    var sticky3 = new Waypoint.Sticky({
      element: $('.logo--fixed-featfosbury')[0]
    });
  }

  if(jQuery(".logo--fixed-arise").length) {
    var sticky4 = new Waypoint.Sticky({
      element: $('.logo--fixed-arise')[0]
    });
  }

  if(jQuery(".section--companies-element-adventure7").length) {
    $('.section--companies-element-adventure7').waypoint(function(direction) {
      $('.logo--fixed-loup').toggleClass('stuck', direction === 'up');
      $('.logo--fixed-loup').toggleClass('sticky-surpassed', direction === 'down');
      }, {
      offset: function() {
          return $('.logo--fixed-loup').outerHeight();
      }
    });
  }

  if(jQuery(".section--companies-element-featfosbury").length) {
    $('.section--companies-element-featfosbury').waypoint(function(direction) {
      $('.logo--fixed-adventure7').toggleClass('stuck', direction === 'up');
      $('.logo--fixed-adventure7').toggleClass('sticky-surpassed', direction === 'down');
      }, {
      offset: function() {
          return $('.logo--fixed-adventure7').outerHeight();
      }
    });
  }

  if(jQuery(".section--companies-element-arise").length) {
    $('.section--companies-element-arise').waypoint(function(direction) {
      $('.logo--fixed-featfosbury').toggleClass('stuck', direction === 'up');
      $('.logo--fixed-featfosbury').toggleClass('sticky-surpassed', direction === 'down');
      }, {
      offset: function() {
          return $('.logo--fixed-featfosbury').outerHeight();
      }
    });
  }

  if(jQuery(".grid-container-ff-bottom").length) {
    $('.grid-container-ff-bottom').waypoint(function(direction) {
      $('.logo--fixed-arise').toggleClass('stuck', direction === 'up');
      $('.logo--fixed-arise').toggleClass('sticky-surpassed', direction === 'down');
      }, {
      offset: function() {
          return $('.logo--fixed-arise').outerHeight();
      }
    });
  }


  if(jQuery("#headline--about").length) {
    var qtx = Telex.widget('headline--about', {speed: 60},[{content: '<h1>About</h1>'}]);
  }
  if(jQuery("#headline--stories").length) {
    var headlineText = jQuery("#headline--stories").next(".headline-content").html();
    headlineText = strip(headlineText);
    var qtx = Telex.widget('headline--stories', {speed: 60},[{content: '<h1>'+headlineText+'</h1>'}]);
  }
  if(jQuery("#headline--news").length) {
    var qtx = Telex.widget('headline--news', {speed: 60},[{content: '<h1>News</h1>'}]);
  }
  if(jQuery("#headline--companies").length) {
    var qtx = Telex.widget('headline--companies', {speed: 60},[{content: '<h1>Companies</h1>'}]);
  }
  if(jQuery("#headline--career").length) {
    var headlineText = jQuery("#headline--career").next(".headline-content").html();
    headlineText = strip(headlineText);
    var qtx = Telex.widget('headline--career', {speed: 60},[{content: '<h1>'+headlineText+'</h1>'}]);
  }
  if(jQuery("#headline--team").length) {
    var qtx = Telex.widget('headline--team', {speed: 60},[{content: '<h1>Team</h1>'}]);
  }
  if(jQuery("#headline--inside").length) {
    var qtx = Telex.widget('headline--inside', {speed: 60},[{content: '<h1>Inside</h1>'}]);
  }
  if(jQuery("#headline--contact").length) {
    var qtx = Telex.widget('headline--contact', {speed: 60},[{content: '<h1>Contact</h1>'}]);
  }

  /* Navigation auf Desktop im Telex Stil bei Hover animieren */
  if(windowWidth >= 1024) {
    var elementCounter = 1;
    var richtung = "left";
    jQuery.each(jQuery(".marquee"), function() {
      if((elementCounter % 2) == 0) {
        richtung = "right";
      } else {
        richtung = "left";
      }
      elementCounter++;
      var currentId = jQuery(this).attr("id");
      var tickerElement = jQuery(this).marquee({
        duplicated: true,
        gap: 0,
        startVisible: true,
        delayBeforeStart: 0,
        duration: 40000,
        direction: richtung,
      });
      /* Animation nach Generierung des Marquee Effekts direkt pausieren, damit es nur ausgelöst wird, wenn man mit der Maus über das Menüelement hovert */
      tickerElement.marquee("pause");
      
      jQuery(document).on("mouseover", "#"+currentId, function() {
        /* Nur dann die Animation bei Mouseover starten, wenn der Browser breiter als 1024 Pixel ist - also größer als ein iPad quer */
        if(windowWidth > 1024) {
          tickerElement.marquee("resume");
        }
      });
      jQuery(document).on("mouseout", "#"+currentId, function() {
        /* Nur dann die Animation bei Mouseover stoppen, wenn der Browser breiter als 1024 Pixel ist - also größer als ein iPad quer */
        if(windowWidth > 1024) {
          tickerElement.marquee("pause");
        }
      });
    });
  }
});

function strip(html) {
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

